import { ShipitUserParameterKey } from '@/views/shipit-administration/enums/shipit-administration';
import { AuthenticationType, UserInterface } from '@/types/shipit-administration';

type GetShipitUserRequest = {
    oid: string;
    name: string;
    authenticationType: AuthenticationType;
    userInterface: UserInterface | null;
    roles: string[];
    isActive: boolean;
    responsibleRegion: number;
    farms: FarmType[];
    parameters: UserParameter[];
    shippers: {
        shippers: UserShipper[];
    };
};

type UserRequestParameter = {
    value: string;
    parameterKey: string;
};

enum FarmType {
    SANDBOX = 'sandbox',
    MAIN = 'main'
}

type CreateShipitUserRequest = {
    name: string;
    oid: string;
    responsibleRegion?: number;
    roles: string[];
    authenticationType: AuthenticationType;
    userInterface: UserInterface;
    contactId: string[];
    farms: FarmType[];
    isActive: boolean;
    parameters: UserRequestParameter[];
};

type EditShipitUserRequest = CreateShipitUserRequest;

type UserParameter = {
    value: string;
    key: string;
};

type DefaultParameter = {
    parameter_key: ShipitUserParameterKey;
    default_value: string;
};

type UserShipper = {
    contactId: string;
    name: string;
    customerId: string;
};

export {
    type GetShipitUserRequest,
    type UserParameter,
    type UserShipper,
    type CreateShipitUserRequest,
    type EditShipitUserRequest,
    type DefaultParameter,
    FarmType
};
