import useMainStore from '@/composables/use-main-store';
import Role from '@/enums/role';
import { glsHasAnyRole } from 'gls-roles';

const hasAnyRole = (roles: Role[]): boolean => {
    const { userRoles } = useMainStore();

    return glsHasAnyRole(userRoles ?? [], roles);
};

export default hasAnyRole;
