import Role from '@/enums/role';
import { AuthenticationType, ConsigneeIdPurpose, PrintLogo, UserInterface } from '@/types/shipit-administration';
import { FarmType } from '@/types/api/shipit-users';

const rolesAllowedToChooseAuthType: Role[] = [Role.MASTER, Role.SHIPIT_COUNTRY_LEAD];

const authenticationType: AuthenticationType[] = [AuthenticationType.BASIC_AUTH, AuthenticationType.O_AUTH_2];
const userInterface: UserInterface[] = [UserInterface.API, UserInterface.WEB];
const printLogo: PrintLogo[] = [PrintLogo.PRINT_LOGO, PrintLogo.NONE];
const consigneeIdPurpose: ConsigneeIdPurpose[] = [ConsigneeIdPurpose.SHIPMENT_REFERENCE, ConsigneeIdPurpose.UNIT_REFERENCE];
const farmTypes: FarmType[] = [FarmType.SANDBOX, FarmType.MAIN];
const FRENCH_CONTACT_IDS_PREFIX: string = '250';
const MASTER_ONLY_NAME: string = 'Core';
const MULTIPLE_VAL_PLACEHOLDER: string = 'Multiple entries found';

export {
    rolesAllowedToChooseAuthType,
    authenticationType,
    userInterface,
    printLogo,
    consigneeIdPurpose,
    farmTypes,
    FRENCH_CONTACT_IDS_PREFIX,
    MASTER_ONLY_NAME,
    MULTIPLE_VAL_PLACEHOLDER
};
