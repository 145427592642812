enum ActionName {
    CREATE_GPP_USER = 'CreateGPPUser',
    EDIT_GPP_USER = 'EditGPPUser',
    DELETE_GPP_USER = 'DeleteGPPUser',
    CREATE_SHIPIT_USER = 'CreateShipITUser',
    EDIT_SHIPIT_USER = 'EditShipITUser',
    DELETE_SHIPIT_USER = 'DeleteShipITUser',
    RESET_SHIPIT_USER_PARAMETER = 'ResetShipITUserParameter',
    SHIPPER_SETTINGS_ROUTING = 'ShipperSettingsRouting',
    RESET_SHIPPER_SETTINGS_ROUTING = 'ResetShipperSettingsRouting',
    SHIPPER_SETTINGS_API = 'ShipperSettingsApi',
    RESET_SHIPPER_SETTINGS_API = 'ResetShipperSettingsApi',
    SHIPPER_SETTINGS_SERVICE = 'ShipperSettingsService',
    SHIPPER_SETTINGS_LABEL = 'ShipperSettingsLabel',
    RESET_SHIPPER_SETTINGS_LABEL = 'ResetShipperSettingsLabel',
    SHIPPER_SETTINGS_HAZARDOUS_GOODS = 'ShipperSettingsHazardousGoods',
    SAVE_SHIPPER_SETTINGS = 'SaveShipperSettings'
}

export default ActionName;
