enum EnvironmentHostName {
    LOCAL = 'localhost',
    DEV = 'dev.gls-attributestore.gls-group.com',
    PRE_STAGE = 'pre-stage.gls-attributestore.gls-group.com',
    STAGE = 'stage.gls-attributestore.gls-group.com',
    MT1 = 'mt1.gls-attributestore.gls-group.com',
    PROD = 'gls-attributestore.gls-group.com'
}

export default EnvironmentHostName;
