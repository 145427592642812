import { isAxiosError } from 'axios';

const glsIsBadRequest = (error: unknown): boolean => {
    if (!isAxiosError(error) || !error.response?.status) {
        return false;
    }

    return error.response.status >= 400 && error.response.status < 500;
};

export { glsIsBadRequest };
