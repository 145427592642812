import { type Plugin } from 'vue';
import { createI18n, VueI18nOptions } from 'vue-i18n';

const LOCAL_STORAGE_KEY: string = 'language';

const glsGetLanguageFromLocalStorage = () => {
    let language = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (!language) {
        language = 'en';
    }

    return language;
};

const glsSetLanguageInLocalStorage = (language: string) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, language);
};

const glsCreateI18nInstance = (
    messages: VueI18nOptions['messages'],
    pluralRules: Record<string, (choice: number) => number> = {}
): Plugin => {
    return createI18n({
        legacy: false,
        locale: glsGetLanguageFromLocalStorage() || 'en',
        fallbackLocale: 'en',
        messages,
        pluralRules: {
            pl: (choice: number): number => {
                if (choice === 1) {
                    return 0;
                }
                if ((choice < 10 && [2, 3, 4].includes(choice)) || (choice > 20 && [2, 3, 4].includes(choice % 10))) {
                    return 1;
                }

                return 2;
            },
            ...pluralRules
        }
    });
};
export { glsGetLanguageFromLocalStorage, glsSetLanguageInLocalStorage, glsCreateI18nInstance };
