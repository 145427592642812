import ActionName from '@/enums/action-name';
import { FeatureName } from '@/enums/feature-names';
import Role from '@/enums/role';
import { RouteName } from '@/enums/route-name';

const REQUIRED_ROLES: Record<FeatureName, Role[]> = {
    [RouteName.GPP_USERS]: [Role.MASTER, Role.GPP_USER, Role.DEVELOPER_READONLY],
    [RouteName.CREATE_GPP_USER]: [Role.MASTER, Role.GPP_USER, Role.DEVELOPER_READONLY],
    [RouteName.EDIT_GPP_USER]: [Role.MASTER, Role.GPP_USER, Role.DEVELOPER_READONLY],
    [ActionName.CREATE_GPP_USER]: [Role.MASTER, Role.GPP_USER],
    [ActionName.EDIT_GPP_USER]: [Role.MASTER, Role.GPP_USER],
    [ActionName.DELETE_GPP_USER]: [Role.MASTER, Role.GPP_USER],
    [RouteName.SHIPIT_ADMINISTRATION]: [
        Role.MASTER,
        Role.SHIPIT_COUNTRY_LEAD,
        Role.SHIPIT_ADVANCED_USER,
        Role.SHIPIT_USER,
        Role.DEVELOPER_READONLY
    ],
    [RouteName.CREATE_SHIPIT_USER]: [
        Role.MASTER,
        Role.SHIPIT_COUNTRY_LEAD,
        Role.SHIPIT_ADVANCED_USER,
        Role.SHIPIT_USER,
        Role.DEVELOPER_READONLY
    ],
    [RouteName.EDIT_SHIPIT_USER]: [
        Role.MASTER,
        Role.SHIPIT_COUNTRY_LEAD,
        Role.SHIPIT_ADVANCED_USER,
        Role.SHIPIT_USER,
        Role.DEVELOPER_READONLY
    ],
    [RouteName.CHANGE_SHIPPER_SETTINGS]: [Role.MASTER, Role.SHIPIT_COUNTRY_LEAD, Role.SHIPIT_ADVANCED_USER, Role.DEVELOPER_READONLY],
    [ActionName.CREATE_SHIPIT_USER]: [Role.MASTER, Role.SHIPIT_COUNTRY_LEAD, Role.SHIPIT_ADVANCED_USER, Role.SHIPIT_USER],
    [ActionName.EDIT_SHIPIT_USER]: [Role.MASTER, Role.SHIPIT_COUNTRY_LEAD, Role.SHIPIT_ADVANCED_USER, Role.SHIPIT_USER],
    [ActionName.DELETE_SHIPIT_USER]: [Role.MASTER, Role.SHIPIT_COUNTRY_LEAD, Role.SHIPIT_ADVANCED_USER],
    [ActionName.RESET_SHIPIT_USER_PARAMETER]: [Role.MASTER, Role.SHIPIT_COUNTRY_LEAD, Role.SHIPIT_ADVANCED_USER, Role.SHIPIT_USER],
    [ActionName.SHIPPER_SETTINGS_ROUTING]: [Role.MASTER, Role.SHIPIT_COUNTRY_LEAD, Role.DEVELOPER_READONLY],
    [ActionName.RESET_SHIPPER_SETTINGS_ROUTING]: [Role.MASTER, Role.SHIPIT_COUNTRY_LEAD],
    [ActionName.SHIPPER_SETTINGS_API]: [Role.MASTER, Role.DEVELOPER_READONLY],
    [ActionName.RESET_SHIPPER_SETTINGS_API]: [Role.MASTER],
    [ActionName.SHIPPER_SETTINGS_SERVICE]: [Role.MASTER, Role.SHIPIT_COUNTRY_LEAD, Role.SHIPIT_ADVANCED_USER, Role.DEVELOPER_READONLY],
    [ActionName.SHIPPER_SETTINGS_LABEL]: [Role.MASTER, Role.SHIPIT_COUNTRY_LEAD, Role.SHIPIT_ADVANCED_USER, Role.DEVELOPER_READONLY],
    [ActionName.RESET_SHIPPER_SETTINGS_LABEL]: [Role.MASTER, Role.SHIPIT_COUNTRY_LEAD, Role.SHIPIT_ADVANCED_USER],
    [ActionName.SHIPPER_SETTINGS_HAZARDOUS_GOODS]: [
        Role.MASTER,
        Role.SHIPIT_COUNTRY_LEAD,
        Role.SHIPIT_ADVANCED_USER,
        Role.DEVELOPER_READONLY
    ],
    [ActionName.SAVE_SHIPPER_SETTINGS]: [Role.MASTER, Role.SHIPIT_COUNTRY_LEAD, Role.SHIPIT_ADVANCED_USER]
};

export default REQUIRED_ROLES;
