import { VueQueryPluginOptions } from '@tanstack/vue-query';
import { GLS_SHORT_QUERY_STALE_TIME } from '../constants/gls-query-stale-time';
import { glsRetryOnlyServerErrors } from '../helpers/gls-retry-only-server-errors';
import { GLS_DEFAULT_QUERY_RETRY_DELAY } from '../constants/gls-query-retry-delay';

const glsVueQueryPluginOptions: VueQueryPluginOptions = {
    queryClientConfig: {
        defaultOptions: {
            queries: {
                staleTime: GLS_SHORT_QUERY_STALE_TIME,
                retry: glsRetryOnlyServerErrors(1),
                retryDelay: GLS_DEFAULT_QUERY_RETRY_DELAY
            },
            mutations: {
                retry: false
            }
        }
    }
};

export { glsVueQueryPluginOptions };
