enum Role {
    MASTER = 'Master',
    GPP_USER = 'GPPUser',
    SHIPIT_COUNTRY_LEAD = 'ShipITCountryLead',
    SHIPIT_ADVANCED_USER = 'ShipITAdvancedUser',
    SHIPIT_USER = 'ShipITUser',
    DEVELOPER_READONLY = 'DeveloperReadonly'
}

export default Role;
