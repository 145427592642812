import { Shipper } from '@/types/api/shippers';

enum AuthenticationType {
    BASIC_AUTH = 'BasicAuth',
    O_AUTH_2 = 'OAuth2.0'
}

enum UserInterface {
    API = 'API',
    WEB = 'WEB'
}

enum PrintLogo {
    PRINT_LOGO = 'PRINT_LOGO',
    NONE = 'NONE',
    MULTIPLE_ENTRIES_FOUND = 'Multiple entries found'
}

enum ConsigneeIdPurpose {
    SHIPMENT_REFERENCE = 'CONSIGNEE_ID_AS_SHIPMENT_REF',
    UNIT_REFERENCE = 'CONSIGNEE_ID_AS_UNIT_REF',
    MULTIPLE_ENTRIES_FOUND = 'MULTIPLE_ENTRIES_FOUND'
}

type ShipitUser = {
    name: string;
    oid: string;
    responsibleRegion: number | null;
    shippers: {
        shippers: Shipper[];
    };
};

type ResponsibleRegion = {
    id: number;
    name: string;
};

export { AuthenticationType, UserInterface, PrintLogo, ConsigneeIdPurpose, type ShipitUser, type ResponsibleRegion };
